<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">举报列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" label="用户昵称">
					<template slot-scope="scope">
						<span style="">{{scope.row.user.nickName}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="用户头像" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="举报活动">
					<template slot-scope="scope">
						<div>活动ID：（{{scope.row.coupon_id}}） 活动名称：{{scope.row.coupon.name}}</div>
						<div class="ml-4">商家：{{scope.row.coupon.merchant.merchant_name}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="举报图片">
					<template slot-scope="scope">
						<template v-if="scope.row.image.length > 0">
							<div class="d-flex m-0">
								<viewer class="mr-2" v-for="(item,index) in scope.row.image">
									<img style="width: 45px;height: 45px;" :src="item.image">
								</viewer>
							</div>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="是否处理" width="140">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" :active-value="20" @change="handleStatus(scope.row)"
							:inactive-value="10"></el-switch>
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column align="center" label="举报内容">
					<template slot-scope="scope">
						{{scope.row.content}}
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="170">
					<template slot-scope="scope">
						<router-link :to="{name:'coupon/detail',params: {coupon_id:scope.row.coupon_id}}">
							<el-button type="text" size="mini" class="table-btn">查看活动</el-button>
						</router-link>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)" class="table-last-btn">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/report/index',
					keyword: '',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			handleStatus(column) {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/report/state',
						report_id: column.report_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					if (res.data.code === 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
					} else {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定'
						})
					}
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该举报吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/report/delete',
							report_id: data.report_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
